(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name neo.directive:toolbar
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="neo">
   <neo-toolbar></neo-toolbar>
   </example>
   *
   */
  angular
  .module('neo')
  .directive('neoToolbar', neoToolbar);

  function neoToolbar() {
    return {
      restrict: 'E',
      scope: {
        logout: "&",
        userName: "="
      },
      templateUrl: 'directives/toolbar/toolbar-directive.tpl.html',
      replace: false,
      controller: 'ToolbarCtrl',
      controllerAs: 'vm'
    };
  }
}());
